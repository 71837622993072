import { useConfigStore } from '@/store/useConfigStore'
import type { Config, SupplierStockStatus } from '~/types/interfaces'

export default defineNuxtPlugin(async () => {
    const { data: configResponse, status } = await useFetch<Config>('/api/configs/getConfigs')
    const { data: supplierStockResponse } = await useFetch<SupplierStockStatus[]>( `/api/configs/availabilityFilters`, { default: () => [] })

    const configStore = useConfigStore()
    const { cdnUrl } = useRuntimeConfig().public
    if(status.value === 'success' && configResponse.value) {
        configStore.setConfig(configResponse.value, supplierStockResponse.value, cdnUrl)
    } else {
        createError({
            message: "Une erreur est survenue lors du chargement de la configuration du site, si le problème persiste, veuillez contacter le support.",
            statusCode: 500
        })
    }    
})