import type { AppMode } from "./types/global";
import type { Entity, Establishment, Supplier, ThemeApp, Links, Term, SupplierStockStatus, Config } from "./types/interfaces";

export default defineAppConfig({
    appMode: <AppMode>'development',
    appConfig: <Config>{},
    establishment: <Establishment>{},
    suppliers: <Supplier[]>[],
    entity: <Entity>{},
    terms: <Term[]>[],
    supplierStockStatus: <SupplierStockStatus[]>[],
    themeApp: <ThemeApp>{
        light:{},
        dark:{}
    },
    query: {
      actions: {
        newVehicle: 'newVehicle'
      }
    },
    version: "v1.332.1-D",
    maxImagesShop: 5
})