const _useConsole = () => {
    const storeConfig = useConfigStore()
    const { appMode } = storeToRefs(storeConfig)

    /**
     * Logs a message to the console, with a prefix indicating whether it was 
     * logged on the server or the client. No logging is performed if the 
     * application is in 'production' mode.
     * 
     * @param {any} [message] - The message or object to log. Optional.
     * @param {string} [title] - An optional title or label to include in the log. Defaults to an empty string.
     * 
     * @returns {void} This function does not return a value.
     */
    function log(message?: any, title?: string): void {
        if(appMode.value === 'production') return
        if(import.meta.server) {
            console.log(`[SERVER] : ${title ?? ''}`, message)
        } else {
            console.log(`[CLIENT] : ${title ?? ''}`, message)
        }
    }

    return {
        log
    }
}

export const useConsole = createSharedComposable(_useConsole)